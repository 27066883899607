<template>
	<div
		class="mt-30 text-left flex-row"
	>

		<drops_side
			@click="toDrops"
		></drops_side>

		<div
			class="flex-1"
		>
			<div class="under-line pb-10 flex-row">
				<h3 class="flex-1">{{ item_card_type.name }}</h3>
			</div>
			<div class="main_card_wrap mt-20">
				<div class="main_card" :class="item_card.nft_card_div_code == 'NF00100005' ? 'main_card_type3' : 'main_card_type1'">
					<figure
						v-if="item_card.nft_card_div_code == 'NF00100005'"
					>
						<div class="frame frame_type3 thumbnail-wrapper">
							<div
								class="thumbnail"
							>
								<img
									:src="item_card.sl_img_url" alt="SPY">
							</div>
							<figcaption class="thumbnail_txt">
								<span class="name">{{  item_card.nft_card_grade_name }}</span>
								<em class="type">{{  item_card.nft_card_div_name }}</em>
								<strong class="price">{{  item_card.sl_coin_token_quantity | makeComma(8)  }} {{ text_unit }}</strong>
							</figcaption>
						</div>
					</figure>
					<div
						v-else
						class="gift_box"
					>
						<a
							class="gift_card mafia_card"
						>
							<div class="tit pa-30">
								<div class="text-left">

									<img
										:src="require('@/assets/image/gift_l.png')" alt="MAFIA CARD BUY"
									/>
								</div>

								<span class="text-left">MAFIA CARD BUY</span>
							</div>
							<div class=""></div>
						</a>
					</div>
				</div>
			</div>

			<div class="mt-30 pb-50">

				<div class="width-480 ma-auto">
					<div class="text-center">
						<button class="btn_count btn_count_down" @click="setCount(false)" :disabled="is_minus"><v-icon large>mdi-minus-circle</v-icon></button>
						<input
							v-model="item_sale.purchase_quantity"
							type="number"
							readonly
							style="background-color: white !important; font-size: 16px; font-weight: bold"
							class="text-center"
						>
						<button class="btn_count btn_count_up" @click="setCount(true)" :disabled="is_plus"><v-icon large>mdi-plus-circle</v-icon></button>
					</div>

					<div class="mt-10">
						<dl class="flex-row justify-space-between under-line pb-10" >
							<dt>판매가</dt>
							<dd class="">{{ total_price | makeComma(8) }} {{ text_unit }}</dd>
						</dl>
						<dl class="flex-row justify-space-between mt-10 under-line pb-10">
							<dt>최종 결제 {{ text_unit }}</dt>
							<dd class="col_red fw-700">{{  total_klay | makeComma(8) }} {{ text_unit }}</dd>
						</dl>
					</div>
					<div class="mt-20 text-center ">
						<button
							v-if="max > 0"
							class="btn-inline btn-primary radius-20 pa-10-20" @click="onPin"
						>{{ item_card.nft_card_div_name }}</button>
						<button
							v-else
							class="btn_l btn_fill_blue"
							disabled=""
						>SOLD OUT</button>
					</div>
				</div>
			</div>
		</div>

		<mafia0431
			v-if="item_result.coin_token_code"
			:user="user"
			:item_card="item_result"

			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 999; background-color: #0f1020"
		></mafia0431>

		<OTP
			v-if="is_otp"
			:options="otp_option"
			style="position: fixed; left: 0; top: 0; width: 100%; z-index: 101; background-color: white"

			@click="postSale"
			@cancel="is_otp = false"
		></OTP>

		<PopupConfirm
			v-if="is_confirm"
			@click="toOtp"
			@cancel="is_confirm = false"
		>
			<template v-slot:title>이중인증 안내</template>
			<template
				v-slot:main-txt
			>이중인증 미등록 상태입니다</template>
			<template
				v-slot:sub-txt
			>Google OTP 설정 후 이용가능합니다.</template>
			<template
				v-slot:name-cancel
			>취소</template>
			<template
				v-slot:name-confirm
			>설정하기</template>
		</PopupConfirm>
	</div>
</template>

<script>
import OTP from "@/view/Auth/mafia111-6"
import mafia0431 from "@/view/Drops/mafia043-1";
import PopupConfirm from "@/view/Layout/PopupConfirm";
import drops_side from "@/view/Drops/drops_side";
export default {
	name: 'mafia0392'
	, props: ['user']
	, components: {drops_side, PopupConfirm, mafia0431, OTP}
	, data: function () {
		return {
			program: {
				name: 'SaleDROPS'
				, type: 'popup'
				, title: this.$route.params.nft_card_div_code == 'NF00100005' ? 'UNION CARD' : 'MAFIA CARD'
			}
			, is_purchase: {
				nft: false
				, utility: false
			}
			, item_card: {
				sl_coin_token_code: ''
				, sl_coin_token_quantity: 0
			}
			, item_sale: {
				purchase_quantity: 1
				, coin_token_quantity: 0
				, pin_number: ''
				, otp_number: ''
			}
			, item_result: {

			}
			, min: 1
			, max: 9999
			, is_otp: false
			, otp_option: {
				otp_type: 'check'
			}
			, is_confirm: false
			, blockchain_div_code: this.$route.params.blockchain_div_code
			, sl_coin_token_code: this.$route.params.sl_coin_token_code
			, math: null
			, item_nft: { }
			, items_card_type: [
				{ code: 'NF00100005', name: 'UNION'}
				, { code: 'NF00100007', name: 'MAFIA NFT'}
			]
			, item_card_type: {
				code: 'NF00100007', name: 'MAFIA NFT'
			}
		}
	}
	, computed: {
		total_price: function(){
			let t = 0
			t = this.item_card.sl_coin_token_quantity
			return t
		}
		, total_klay: function(){
			let t = 0

			let d = ('' + this.item_card.sl_coin_token_quantity).split('.')
			if(d.length > 1){
				let m = d[1].length
				t = this.item_card.sl_coin_token_quantity * this.item_sale.purchase_quantity

				let dd = ('' + t).split('.')
				let mm = dd[1].length
				if(mm > m){
					t = t.toFixed(m)
				}
			}else{
				t = this.item_card.sl_coin_token_quantity * this.item_sale.purchase_quantity
			}

			return t
		}
		, is_minus: function(){
			let t = false
			if(this.item_sale.purchase_quantity <= this.min){
				t = true
			}
			return t
		}
		, is_plus: function(){
			let t = false
			if(this.item_sale.purchase_quantity >= this.max){
				t = true
			}
			return t
		}
		, text_unit: function(){
			let t = ''
			switch(this.item_card.sl_coin_token_name){
				case 'Klaytn':
					t = 'KLAY'
					break
				case 'ETH':
					t = 'ETH'
					break
				case 'REAP':
					t = 'REAP'
					break
			}
			return t
		}
		, text_card_type_name: function(){
			let t = ''

			return t
		}
	}
	, methods: {
		getData: async function () {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_sales_card_info
					, data: {
						member_number: this.user.member_number
						, nft_sl_number: this.$route.params.nft_sl_number
						, blockchain_div_code: this.blockchain_div_code
						, sl_coin_token_code: this.sl_coin_token_code
					}
				})

				if (result.success) {
					this.item_card = result.data
					let t = this.item_card.sl_quantity - this.item_card.sl_compl_quantity
					if(this.max > t){
						this.max = t
					}
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getNftConfig: async function(){

			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_drops_config
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.item_card = result.data
					this.item_card.sl_coin_token_list.forEach( coin => {
						if(coin.blockchain_div_code == this.blockchain_div_code){
							this.item_card.blockchain_div_code = coin.blockchain_div_code
							this.item_card.sl_coin_token_code = coin.sl_coin_token_code
							this.item_card.sl_coin_token_name = coin.sl_coin_token_name
							this.item_card.sl_coin_token_quantity = coin.sl_coin_token_quantity
						}
					})
					let t = this.item_card.sl_quantity - this.item_card.sl_compl_quantity
					if(this.max > t){
						this.max = t
					}
					console.log('this.item_nft', this.item_nft)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postSale: function(otp_number){
			if(this.item_card.nft_card_div_code == 'NF00100005'){
				this.postUnionDrop(otp_number)
			}else if(this.item_card.nft_card_div_code == 'NF00100007'){
				this.postNftDrop(otp_number)
			}
		}
		, postUnionDrop: async function (otp_number) {
			try {

				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_sales_card
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.item_sale.pin_number
						, gogle_otp_certfc_number: otp_number
						, nft_sl_number: this.$route.params.nft_sl_number
						, purchase_quantity: this.item_sale.purchase_quantity + ''
						, coin_token_code: this.item_card.sl_coin_token_code
						, coin_token_quantity: this.total_klay + ''
					}
				})

				if (result.success) {
					this.item_result = result.data
				} else {
					throw result.message
				}
			} catch (e) {
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
				this.is_otp = false
			}
		}
		, postNftDrop: async function(otp_number){
			let url = this.$api_url.api_path.post_nft_drop
			try {
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, pinnumber: this.item_sale.pin_number
						, gogle_otp_certfc_number: otp_number
						, nft_sl_number: this.$route.params.nft_sl_number
						, tot_purchase_quantity: this.item_sale.purchase_quantity + ''
						, coin_token_code: this.item_card.sl_coin_token_code
						, tot_purchase_coin_token_quantity: this.total_klay + ''
					}
					, type: true
				})

				if(result.success){
					this.item_result = result.data
					this.$set(this.item_result, 'coin_token_code', result.data.purchase_coin_token_code)
				}else{
					throw result.message
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_otp = false
			}
		}
		, toNft: function () {
			if (this.is_purchase.nft) {
				this.$emit('to', {name: 'mafia040', params: {card_type: 'nft'}})
			}
		}
		, toUtility: function () {
			if (this.is_purchase.utility) {
				this.$emit('to', {name: 'mafia040', params: {card_type: 'utility'}})
			}
		}
		, setCount: function(type){
			if(type){
				if(this.item_sale.purchase_quantity < this.max){
					this.item_sale.purchase_quantity++
				}else{
					this.item_sale.purchase_quantity = this.max
				}
			}else{
				if(this.item_sale.purchase_quantity > this.min){
					this.item_sale.purchase_quantity--
				}else{
					this.item_sale.purchase_quantity = this.min
				}
			}
		}
		, onPin: function(){
			if(this.user.gogle_otp_reg_fg == 'Y'){
				this.$bus.$emit('onPin', 'check')
				this.$bus.$on('pinCallback', (type, pin_number) => {
					this.item_sale.pin_number = pin_number
					this.onOtp()
				})
			}else{
				this.is_confirm = true
			}
		}
		, onOtp: function(){
			this.is_otp = true
		}
		, toOtp: function(){
			this.$bus.$emit('to', { name: 'mafia1113'})
		}
		, toDrops: function(item){
			this.$bus.$emit('to', item.to)
		}
	}

	, created() {
		this.$emit('onLoad', this.program)

		if(this.$route.params.nft_card_div_code == 'NF00100005'){
			this.getData()
		}else{
			this.getNftConfig()
		}

		this.math = window.math
	}
}
</script>